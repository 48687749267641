import classnames from "classnames";
import styles from "./ServiceListItem.module.scss";

import { Row, Col, Stack } from "react-bootstrap";

type ServiceListItemType = {
  icon: string;
  title: string;
  paragraph: string;
};

export const ServiceListItem = ({
  icon,
  title,
  paragraph,
}: ServiceListItemType) => {
  return (
    <Stack direction="horizontal" gap={4}>
      <div
        style={{
          minWidth: "4rem",
        }}>
        <img src={icon} />
      </div>
      <div>
        <h4 className={classnames(styles["text-header"], "mb-0")}>{title}</h4>

        <p className={classnames("pt-2", styles["paragraph"])}>{paragraph}</p>
      </div>
    </Stack>
  );
};

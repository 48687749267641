import { useState } from "react";
import classnames from "classnames";
import styles from "./ContactBlock.module.scss";

import blurred from "../../res/backgrounds/blurred.png";

import {
  Container,
  Row,
  Col,
  FormControl,
  FormGroup,
  Button,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";

type ContactBlockProps = {};

export const ContactBlock = (props: ContactBlockProps) => {
  const [name, setName] = useState("");
  const [document, setDocument] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  return (
    <div
      id="contacto"
      className={classnames("padding")}
      style={{
        backgroundImage: `url(${blurred})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}>
      <Container>
        <div className="flex-center">
          <div className={classnames(styles["contact-form"])}>
            <h3 className={classnames(styles["text-header"], "text-center")}>
              CONTÁCTANOS
            </h3>

            <Row className={classnames("mt-3")}>
              <Col className="mt-3">
                <FormGroup>
                  <FormControl
                    className={classnames(styles["custom-form"])}
                    placeholder="Escribe tu nombre"
                    value={name}
                    onChange={(e) => {
                      setName(e.currentTarget.value);
                    }}
                  />
                </FormGroup>

                <FormGroup className={classnames("mt-3")}>
                  <FormControl
                    className={classnames(styles["custom-form"])}
                    placeholder="Numero de documento"
                    value={document}
                    onChange={(e) => {
                      setDocument(e.currentTarget.value);
                    }}
                  />
                </FormGroup>

                <FormGroup className={classnames("mt-3")}>
                  <FormControl
                    className={classnames(styles["custom-form"])}
                    placeholder="Correo electronico"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.currentTarget.value);
                    }}
                  />
                </FormGroup>
              </Col>

              <Col className="mt-3">
                <FormGroup>
                  <FormControl
                    className={classnames(styles["custom-form"])}
                    placeholder="Telefono de contacto"
                    value={phone}
                    onChange={(e) => {
                      setPhone(e.currentTarget.value);
                    }}
                  />
                </FormGroup>

                <FormGroup className={classnames("mt-3")}>
                  <FormControl
                    style={{
                      height: "92.5px",
                    }}
                    as="textarea"
                    rows={3}
                    className={classnames(styles["custom-form"])}
                    placeholder="Escribe tu mensaje"
                    value={message}
                    onChange={(e) => {
                      setMessage(e.currentTarget.value);
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>

            <div className={classnames("mt-3", "flex-center")}>
              <Button
                className={classnames(styles["custom-button"])}
                onClick={() => {
                  let errorCheck = 0;
                  let messages = [];

                  if (String(name).trim() === "") {
                    errorCheck = 1;
                    messages.push("Por favor coloque su nombre");
                  }

                  if (
                    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
                      email
                    ) === false
                  ) {
                    errorCheck = 1;
                    messages.push("Email invalido");
                  }

                  if (String(phone).trim() === "") {
                    errorCheck = 1;
                    messages.push("Por favor coloque su numero de telefono");
                  }

                  if (String(message).trim() === "") {
                    errorCheck = 1;
                    messages.push("Por favor coloque el mensaje");
                  }

                  if (errorCheck === 0) {
                    const formData = new FormData();

                    formData.append("nombre", name);
                    formData.append("documento", document);
                    formData.append("correo", email);
                    formData.append("telefono", phone);
                    formData.append("mensaje", message);
                    formData.append(
                      "access_key",
                      "7b9dd80e-3818-4011-9fbb-86cfac48796b"
                    );
                    //  formData.append("avatar", fileField.files[0]);

                    fetch("https://api.web3forms.com/submit", {
                      method: "POST",
                      body: formData,
                    })
                      .then((response) => response.json())
                      .then((result) => {
                        console.log("Success:", result);

                        toast.success("Gracias por contactarnos!");

                        setName("");
                        setDocument("");
                        setPhone("");
                        setEmail("");
                        setMessage("");
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      })
                      .catch((error) => {
                        console.error("Error:", error);
                        toast.error("Algo salio mal :(");
                      });
                  } else {
                    for (let i = 0; i < messages.length; i++) {
                      toast.error(messages[i]);
                    }
                  }
                }}>
                Enviar
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

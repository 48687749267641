import "./App.scss";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Landing } from "./pages/Lading";

function App() {
  return (
    <>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Landing />} />
          </Routes>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;

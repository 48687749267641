import styles from './WhoBlock.module.scss';
import classnames from 'classnames';

import { Container, Row, Col } from 'react-bootstrap';

import worldMap from '../../res/misc/world_map.png';

import who_are_we_01 from '../../res/misc/who_are_we_01.png';
import who_are_we_02 from '../../res/misc/who_are_we_02.png';
import wellness from '../../res/backgrounds/wellness.png';

type WhoBlockProps = {};

export const WhoBlock = (props: WhoBlockProps) => {
  return (
    <div
      id="quienes-somos"
      className={classnames(styles['who-block'])}
      style={{
        backgroundImage: `url(${wellness})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        minHeight: '500px',
      }}
    >
      <Container className={classnames('padding')}>
        <Row>
          <Col lg={6}>
            <h1 className={classnames(styles['text-header'])}>QUIENES SOMOS</h1>

            <p className={classnames('mt-2')}>
              Grupo Cuidate Plus es una plataforma de servicios asistenciales
              que ofrece soluciones integrales de salud y vialidad para nuestros
              clientes. Mejoramos la calidad de vida de nuestros afiliados con
              productos y servicios especializados en asistencias, y lo hacemos
              con un equipo altamente calificado y tecnología de vanguardia.
              <br />
              <br />
              Ofrecemos servicios a nivel nacional con calidad asegurada en cada
              uno de nuestros procesos y recursos. Nuestro propósito es ofrecer
              productos y servicios de calidad a precios acordes para todas las
              opciones.
            </p>
          </Col>
          <Col lg={6}>
            <div className="flex-left align-items-center">
              <img
                className={classnames(styles['image-01'])}
                src={who_are_we_01}
              />
              <img
                className={classnames(styles['image-02'])}
                style={{
                  maxWidth: '70%',
                }}
                src={who_are_we_02}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

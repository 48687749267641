import React from 'react';

import { Container, Stack } from 'react-bootstrap';
import { ServiceListItem } from './ServiceListItem/ServiceListItem';
import classnames from 'classnames';

import styles from './ServicesListBlock.module.scss';

import telemedicine from '../../res/icons/telemedicine.png';
import home_assistance from '../../res/icons/home_assistance.png';
import pharmacy_lab from '../../res/icons/pharmacy_lab.png';
import funeral_prevention from '../../res/icons/funeral_prevention.png';
import emergencies_scheduled from '../../res/icons/emergencies_scheduled.png';
import assistance_travel from '../../res/icons/assistance_travel.png';
import affiliate_assistance from '../../res/icons/affiliate_assistance.png';
// import pharmacy_lab from "../../res/icons/p"

type ServicesListBlockType = {};

export const ServicesListBlock = (props: ServicesListBlockType) => {
  return (
    <div className={classnames(styles.background)}>
      <Container className={classnames('padding')}>
        <h1 className={classnames(styles['text-header'])}>
          NUESTROS SERVICIOS
        </h1>

        <Stack className="mt-4" gap={4}>
          <ServiceListItem
            icon={telemedicine}
            title="TELEMEDICINA"
            paragraph={`Es un servicio telefónico en el que un equipo profesional de médicos certificados, apoyados en
programas expertos y tecnología de última generación, orienta directamente a nuestros afiliados
ante cualquier problema o duda que tengan relativa a su salud y bienestar.`}
          />

          <ServiceListItem
            icon={home_assistance}
            title="ATENCION MEDICA DOMICILARIA"
            paragraph={`Es un servicio de vanguardia que consiste en la atención y asistencia médica inmediata con
unidades especialmente dotadas de los equipos necesarios para atender los requerimientos de
salud de nuestros afiliados en el lugar donde se encuentren y su traslado a un centro asistencial si
ello fuera necesario. Este servicio representa la primera forma de atención presencial a nuestros
afilados, brindándoles comodidad, seguridad y confianza.`}
          />

          <ServiceListItem
            icon={pharmacy_lab}
            title="FARMACIA & LABORATORIO"
            paragraph={`Servicio de entrega de medicamentos y realización de exámenes paraclínicos para nuestros
afiliados, que es su mayoría pueden disfrutarse desde la comodidad de su hogar. Dentro de
nuestros aliados comerciales, mantenemos una relación profesional con Farmarket, consolidada
empresa venezolana de farmacia con más de 20 años de experiencia sirviendo al sector salud.`}
          />

          <ServiceListItem
            icon={funeral_prevention}
            title="PREVENCION FUNERARIA"
            paragraph={`Para esos momentos sensibles de nuestros afiliados, contamos con nuestro servicio completo de
previsión funeraria, en donde ofrecemos una alta gama de opciones que se ajustan a cada
necesidad.`}
          />

          <ServiceListItem
            icon={emergencies_scheduled}
            title="TRASLADE DE EMERGENCIAS & PROGRAMADOS"
            paragraph={`Traslados de pacientes que por su condición, luego de ser evaluados, ameriten ser llevados a un
centro asistencial de salud para complementar su atención médica.`}
          />

          <ServiceListItem
            icon={assistance_travel}
            title="ASISTENCIAS VIAJES"
            paragraph={`Ofrecemos soluciones de asistencia vial para nuestros afiliados, con los que podrán contar con un
equipo dedicado a su servicio y atención en caso de necesitarlos. Remolque, acompañamiento
vial, auxilio de batería, inflado/cambio de neumáticos, mecánica a domicilio, entre otras
soluciones, están a la disposición de nuestros afiliados.`}
          />

          <ServiceListItem
            icon={affiliate_assistance}
            title="ATENCION AL AFILIADO"
            paragraph={`Atención oportuna de las necesidades de nuestros afiliados, a través de nuestro Centro Integral
de Servicios (CIS) donde tramitamos atención de solicitudes, reclamos, planteamientos,
sugerencias. Atención VIP para altos ejecutivos. Charlas del servicio de salud a nivel nacional.`}
          />
        </Stack>
      </Container>
    </div>
  );
};

import classnames from 'classnames';
import styles from './FoundationBlock.module.scss';

import { Container } from 'react-bootstrap';
import { Divider } from '../Divider/Divider';

import codeiv from '../../res/logo/codeiv.png';
import vidamed from '../../res/logo/vidamed.png';
import pdvsa from '../../res/logo/pdvsa.png';
import colegio_de_economistas from '../../res/logo/colegio_de_economistas.png';
import bcv from '../../res/logo/bcv.png';
import sanitas_venezuela from '../../res/logo/sanitas_venezuela.png';
import multinacional_seguros from '../../res/logo/multinacional_seguros.png';
import farmarket from '../../res/logo/farmarket.jpg';
import fundacion_seguros_ven from '../../res/logo/fundacion_seguros_ven.png';
import mapfre from '../../res/logo/mapfre.png';

type FundationBlockProps = {};

export const FoundationBlock = (props: FundationBlockProps) => {
  return (
    <>
      <div className={classnames('padding', styles.message)} id="fundacion">
        <Container>
          <h3 className="text-center mb-0">
            PARA COTIZACIONES COLECTIVAS:
            <br /> CONTACTANOS PARA DISEÑAR EL PLAN A TU MEDIDA
          </h3>
        </Container>
      </div>
      <Container className="padding">
        <h2 className={classnames('text-center', styles['text-header'])}>
          ALIANZAS COMERCIALES
        </h2>

        <div className="flex-center align-items-center mt-4">
          <img
            className={classnames(styles['image-partners'])}
            style={{
              height: '6rem',
            }}
            src={codeiv}
          />

          <img
            style={{
              height: '8rem',
            }}
            className={classnames(styles['image-partners'])}
            src={colegio_de_economistas}
          />

          <img
            style={{
              height: '6rem',
            }}
            className={classnames(styles['image-partners'])}
            src={farmarket}
          />

          <img
            style={{
              height: '6rem',
            }}
            className={classnames(styles['image-partners'])}
            src={fundacion_seguros_ven}
          />

          <img
            style={{
              height: '6rem',
            }}
            className={classnames(styles['image-partners'])}
            src={mapfre}
          />

          <img
            className={classnames(styles['image-partners'])}
            style={{
              height: '7rem',
            }}
            src={bcv}
          />
        </div>
      </Container>
    </>
  );
};

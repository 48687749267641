import classnames from 'classnames';
import styles from './Footer.module.scss';

import logo_white from '../../res/logo/logo_white.png';

import { Container, Stack, StackProps, Row, Col } from 'react-bootstrap';

export type FooterType = {};

export const Footer = (props: FooterType) => {
  return (
    <div className={classnames(styles.footer)}>
      <Container className={classnames('padding')}>
        <Row>
          <Col md={4} className="mt-3">
            <img src={logo_white} />
          </Col>

          <Col md={8} className="mt-3">
            <Stack gap={2}>
              <h5>Sede Principal</h5>

              <Stack direction="horizontal" gap={1}>
                <span className="material-icons align-middle">place</span>
                <p className="mb-0">
                  Torre Capaya, al frente del edificio de Conatel, piso 2, ofic.
                  26, calle Veracruz, Urbanización Las Mercedes, Municipio
                  Baruta, Caracas.
                </p>
              </Stack>

              <Stack direction="horizontal" gap={1}>
                <span className="material-icons">phone_iphone</span>
                <p className="mb-0">(0212) 992.2879 - 992.5405 - 992.2986.</p>
              </Stack>

              <Stack direction="horizontal" gap={1}>
                <span className="material-icons">email</span>
                <p className="mb-0">
                  ventas@grupocuidatemas.com cis@grupocuidatemas.com
                </p>
              </Stack>
            </Stack>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

import { Container } from 'react-bootstrap';

import styles from './ServicesBlock.module.scss';

import { Divider } from '../Divider/Divider';

import classnames from 'classnames';

import servicesIllustration from '../../res/misc/services_illustration.png';

type ServicesBlockType = {};

export const ServicesBlock = (props: ServicesBlockType) => {
  return (
    <Container className={classnames('padding')} id="servicios">
      <h1 className={classnames('text-center', styles['text-header'])}>
        INDICADORES DE SERVICIO
      </h1>

      <div className={classnames('mt-3', 'flex-center')}>
        <img
          src={servicesIllustration}
          style={{
            maxWidth: '100%',
          }}
        />
      </div>
    </Container>
  );
};

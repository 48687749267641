import classnames from "classnames";
import styles from "./PlanItem.module.scss";

import { Stack } from "react-bootstrap";

type PlanItemProps = {
  icon: string;
  title: string;
  number: string;
};

export const PlanItem = ({ icon, title, number }: PlanItemProps) => {
  return (
    <Stack direction="horizontal" gap={4}>
      <div
        style={{
          minWidth: "3rem",
        }}>
        <img src={icon} />
      </div>
      <Stack direction="horizontal" gap={2}>
        <h1 className={classnames(styles.number)}>{number}</h1>
        <h4 className={classnames(styles["text-header"], "mb-0")}>{title}</h4>
      </Stack>
    </Stack>
  );
};

import styles from './MainBlock.module.scss';
import classnames from 'classnames';

import { Container, Button, Stack } from 'react-bootstrap';

import main_background from '../../res/backgrounds/main_background.png';

type MainBlockProps = {};

export const MainBlock = (props: MainBlockProps) => {
  return (
    <div
      id="inicio"
      className={classnames(styles['main-block'])}
      style={{
        backgroundImage: `url(${main_background})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        minHeight: '500px',
      }}
    >
      <Container fluid className={classnames('padding')}>
        <div className={classnames(styles['heading-box'])}>
          <h1>
            Protegemos tu salud <br /> donde te encuentres
          </h1>

          <p className={classnames('mt-2', styles.paragraph)}>
            Somos la solución de bienestar de vida, al alcance de sus
            realidades.
          </p>

          <Stack direction="horizontal" gap={3}>
            <Button
              className="btn-custom-primary"
              size="sm"
              onClick={() => {
                window.open(`mailto:ventas@grupocuidatemas.com`);
              }}
            >
              <p className={classnames('mb-0', styles['text-button'])}>
                AFILIACION <br />
                DE PERSONA <br /> NATURAL
              </p>
            </Button>

            <Button
              className="btn-custom-secondary ml-2"
              size="sm"
              onClick={() => {
                window.open(`mailto:ventas@grupocuidatemas.com`);
              }}
            >
              <p className={classnames('mb-0', styles['text-button'])}>
                AFILIACION <br />
                DE PERSONA
                <br /> JURIDICA
              </p>
            </Button>
          </Stack>
        </div>
      </Container>
    </div>
  );
};

import { useEffect } from "react";

import { Container, Nav, Navbar, NavDropdown, Button } from "react-bootstrap";
import logo from "../../res/logo/logo.png";

import styles from "./NavbarComponent.module.scss";
import $ from "jquery";

type NavbarProps = {};

export const NavbarComponent = (props: NavbarProps) => {
  useEffect(() => {
    $(function () {
      $(document).scroll(function (): void {
        var $nav = $(".navbar");

        $nav.toggleClass(
          "scrolled shadow-sm navbar-opacity-1",
          //@ts-ignore
          $(this)?.scrollTop() > $nav?.height()
        );
      });
    });
  }, []);
  return (
    <Navbar
      collapseOnSelect
      expand="md"
      className={styles["navbar-custom"]}
      fixed="top">
      <Container>
        <Navbar.Brand href="#inicio">
          <img className="logo" src={logo} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="#quienes-somos">QUIENES SOMOS</Nav.Link>
            <Nav.Link href="#servicios">SERVICIOS</Nav.Link>
            <Nav.Link href="#planes">PLANES</Nav.Link>
            <Nav.Link href="#fundacion">FUNDACIÓN</Nav.Link>
            <Nav.Link href="#contacto">CONTACTO</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

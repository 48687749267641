import classnames from 'classnames';
import styles from './PlansBlock.module.scss';

import { Container, Row, Col, Stack } from 'react-bootstrap';

import cuidate_simplified from '../../res/icons/cuidate_simplified.png';

import { PlanItem } from './PlanItem/PlanItem';

import medical_attention from '../../res/icons/medical_attention.png';
import limited_telemedicine from '../../res/icons/limited_telemedicine.png';
import pharmacy_lab from '../../res/icons/pharmacy_lab_black.png';
import roadside_assistance from '../../res/icons/roadside_assistance.png';
import funeral_prevention from '../../res/icons/funeral_prevention_black.png';
import crosses from '../../res/misc/crosses.png';

type PlansBlockProps = {};

export const PlansBlock = (props: PlansBlockProps) => {
  return (
    <div
      className={classnames(styles.plans)}
      style={{
        backgroundImage: `url(${crosses})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <Container className={classnames('padding')} id="planes">
        <h3 className={classnames('text-center', 'mb-3')}>
          PARA NUESTROS AFILIADOS CONTAMOS CON ESTOS MODELOS DE PLANES
        </h3>
        <h1 className={classnames('text-center', styles['text-header'])}>
          PLANES PENSADOS <br />
          <h3 className={classnames('ml-3')}>PARA TUS NECESIDADES</h3>
        </h1>

        <Row className={classnames('')}>
          <Col className="mt-5">
            <div
              className="flex-left align-items-start"
              style={{
                minHeight: '80px',
              }}
            >
              <img src={cuidate_simplified} />

              <h3
                style={{
                  marginLeft: '1rem',
                }}
              >
                Protector <br /> Familiar
              </h3>
            </div>

            <Stack className="mt-3" gap={4}>
              <PlanItem
                icon={medical_attention}
                title="Atencion Medica Domiciliaria"
                number="4"
              />

              <PlanItem
                icon={limited_telemedicine}
                title="Telemedicina Limitada"
                number=""
              />

              <PlanItem
                icon={pharmacy_lab}
                title="Farmacia & Laboratorio on demand"
                number=""
              />

              <PlanItem
                icon={roadside_assistance}
                title="Asistencia Vial"
                number="1"
              />

              <PlanItem
                icon={funeral_prevention}
                title="Prevencion Funeraria"
                number=""
              />
            </Stack>
          </Col>

          <Col className="mt-5">
            <div
              className="flex-left align-items-start"
              style={{
                minHeight: '80px',
              }}
            >
              <img src={cuidate_simplified} />

              <h3
                style={{
                  marginLeft: '1rem',
                }}
                className="ml-2"
              >
                Tu
              </h3>
            </div>

            <Stack className="mt-3" gap={4}>
              <PlanItem
                icon={medical_attention}
                title="Atencion Medica Domiciliaria"
                number="2"
              />

              <PlanItem
                icon={limited_telemedicine}
                title="Telemedicina Limitada"
                number=""
              />

              <PlanItem
                icon={pharmacy_lab}
                title="Farmacia & Laboratorio on demand"
                number=""
              />

              <PlanItem
                icon={roadside_assistance}
                title="Asistencia Vial"
                number="1"
              />

              <PlanItem
                icon={funeral_prevention}
                title="Prevencion Funeraria"
                number=""
              />
            </Stack>
          </Col>

          <Col className="mt-5">
            <div
              className="flex-left align-items-start"
              style={{
                minHeight: '80px',
              }}
            >
              <img src={cuidate_simplified} />
              <h3
                style={{
                  marginLeft: '1rem',
                }}
                className="ml-2"
              >
                En linea
              </h3>
            </div>

            <Stack className="mt-3" gap={4}>
              <PlanItem
                icon={medical_attention}
                title="Atencion Medica Domiciliaria"
                number="1"
              />

              <PlanItem
                icon={limited_telemedicine}
                title="Telemedicina Limitada"
                number=""
              />
            </Stack>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

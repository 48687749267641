import { NavbarComponent } from "../components/NavbarComponent/NavbarComponent";
import { MainBlock } from "../components/MainBlock/MainBlock";
import { ServicesBlock } from "../components/ServicesBlock/ServicesBlock";
import { WhoBlock } from "../components/WhoBlock/WhoBlock";
import { ServicesListBlock } from "../components/ServicesListBlock/ServicesListBlock";
import { PlansBlock } from "../components/PlansBlock/PlansBlock";
import { FoundationBlock } from "../components/FoundationBlock/FoundationBlock";
import { ContactBlock } from "../components/ContactBlock/ContactBlock";
import { Footer } from "../components/Footer/Footer";

export const Landing = () => {
  return (
    <div>
      <NavbarComponent />

      <MainBlock />

      <ServicesBlock />

      <WhoBlock />

      <ServicesListBlock />

      <PlansBlock />

      <FoundationBlock />

      <ContactBlock />

      <Footer />
    </div>
  );
};
